<template>
  <a-modal
    v-model="addBudgetVisible"
    :title="(isAdd ? '新建' : '编辑') + '预算包'"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    width="1100px"
  >
    <div class="traffic-modal-container">
      <a-form-model ref="ruleForm" :model="formquery">
        <div class="contral-box">
          <div class="contral-left">
            <a-form-model-item
              :rules="[{ required: true, message: '请输入包名', trigger: 'blur' }]"
              style="width: 360px"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 14 }"
              label="预算包名称"
              prop="name"
            >
              <a-input v-model="formquery.name" placeholder="请输入预算包名称" />
            </a-form-model-item>
            <a-button type="primary" @click="handleAddPlace">新增广告源</a-button>
          </div>
          <div class="contral-right">
            <a-form-model-item
              :rules="[{ required: true, message: '请选择操作系统', trigger: 'change' }]"
              prop="osList"
              v-if="formquery.isDefault"
            >
              <m-select-more
                style="width: 200px"
                v-model="formquery.osList"
                :allData="[
                  { id: '1', name: '安卓' },
                  { id: '0', name: 'IOS' }
                ]"
                :searchById="false"
                :hasIcon="false"
                :showId="false"
                label="操作系统"
                :showSelectedPart="true"
                width="450px"
                :canSelectAll="true"
              />
            </a-form-model-item>
            <a-form-model-item
              :rules="[{ required: true, message: '请选择广告样式', trigger: 'change' }]"
              prop="positionList"
              v-if="formquery.isDefault"
            >
              <m-select-more
                style="width: 200px"
                v-model="formquery.positionList"
                :allData="positionList"
                :searchById="false"
                :hasIcon="false"
                :showId="false"
                label="广告样式"
                :showSelectedPart="true"
                width="450px"
                :canSelectAll="true"
              />
            </a-form-model-item>
            <span style="margin-left: 10px; margin-top: 7px">默认预算包</span>
            <a-switch style="margin-top: 7px" :checked="+formquery.isDefault === 1" @change="changeIsDefault" />
          </div>
        </div>
        <div class="search-box">
          <h4 style="padding-left: 10px">已绑定广告源：</h4>
          <div class="search-right">
            <m-select-more
              style="width: 220px"
              v-model="query.platIdList"
              :allData="platList"
              :searchById="false"
              :hasIcon="false"
              :showId="false"
              label="广告平台"
              :showSelectedPart="true"
              width="450px"
              :canSelectAll="true"
            />
            <a-input
              style="width: 220px; margin: 0 5px"
              v-model="query.key"
              placeholder="请输入所属账户/广告源名称ID"
            />
            <a-button type="primary" @click="handleSearchDsp" style="margin-right: 15px">查询</a-button>
          </div>
        </div>
        <div class="source-list-wrapper">
          <div class="source-item head">
            <div class="partner-box item-box">广告平台</div>
            <div class="app-box item-box">所属账户</div>
            <div class="source-box item-box">广告源</div>
            <div class="action-box item-box">操作</div>
          </div>
          <div class="source-item" v-for="item in renderList" :key="item.key">
            <div class="partner-box item-box">{{ item.platName }}</div>
            <div class="app-box item-box">{{ item.platAccountName }}</div>
            <div class="source-box item-box">
              <a-popover placement="bottomLeft" trigger="hover">
                {{ item.dspName }}
                <div class="info-box" slot="content">
                  <span>ID:</span>
                  <div>
                    <span class="value">{{ item.platPlaceId }}</span>
                    <span class="copy" @click="copyId('' + item.platPlaceId)">复制</span>
                  </div>
                </div>
              </a-popover>
            </div>
            <div class="action-box item-box">
              <a-popconfirm
                title="确认移除已配置广告源?"
                ok-text="是"
                cancel-text="否"
                @confirm="handleDeleteEditSourceItem(item)"
              >
                <a-button type="link" style="color: red">删除</a-button>
              </a-popconfirm>
            </div>
          </div>
        </div>
      </a-form-model>
    </div>
    <batchAddBudgetSourceModal
      :budgetInfo="{
        name: formquery.name,
        id: budgetId
      }"
      :visible="addSourceVisible"
      @modalCancel="addSourceVisible = false"
      @configSourceSuccess="configSourceSuccess"
      :platList="platList"
    />
  </a-modal>
</template>

<script>
import { getApiPlatformList } from '@/apiForManage/aggregate'
import { getDspListByBudget, updateBudget } from '@/apiForManage/strategy'
import { copy } from '@/utils/string'
import batchAddBudgetSourceModal from '../batchAddBudgetSourceModal'
import mixGetList from '@/mixins/getListForManage'
export default {
  name: 'AddDspBudgetModal',
  components: { batchAddBudgetSourceModal },
  mixins: [mixGetList],
  data () {
    return {
      formquery: {
        name: '',
        isDefault: 1,
        osList: [],
        positionList: []
      },
      platList: [],
      query: {
        platIdList: [],
        key: ''
      },
      editList: [],
      renderList: [],
      budgetId: undefined,
      addSourceVisible: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    budgetInfo: {
      default: () => ({}),
      type: Object
    }
  },
  async created () {
    this.getApiPlatform()
    await this.getPositionList()
    this.positionList.forEach((item) => {
      item.id = '' + item.id
    })
  },
  watch: {
    visible: {
      handler (val) {
        if (val) {
          // 初始化搜索项
          this.query.platIdList = []
          this.query.key = ''
          // 初始化预算包ID
          this.budgetId = undefined
          this.editList = []
          this.renderList = []
          if (!this.isAdd) {
            // 编辑
            // 获取预算包ID和信息
            this.budgetId = this.budgetInfo.id
            this.formquery = {
              name: undefined,
              isDefault: 0,
              osList: [],
              positionList: [],
              ...this.budgetInfo
            }
            if (+this.formquery.isDefault) {
              this.formquery.positionList = this.budgetInfo.positionList?.map((item) => '' + item)
              this.formquery.osList = this.budgetInfo.osList?.map((item) => '' + item)
            }
            // 获取已添加的广告源
            this.getDspListByBudget()
          } else {
            // 添加
            // 初始化信息及ID
            this.budgetId = undefined
            this.formquery = {
              name: undefined,
              isDefault: 0,
              osList: [],
              positionList: []
            }
          }
          this.$nextTick(() => {
            this.$refs.ruleForm.clearValidate()
          })
        }
      }
    }
  },
  computed: {
    addBudgetVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    changeIsDefault (e) {
      this.formquery.isDefault = e ? 1 : 0
    },
    // 获取api平台列表
    async getApiPlatform () {
      const { data = [] } = await getApiPlatformList()
      this.platList = data
    },
    // 删除广告源
    handleDeleteEditSourceItem (row) {
      updateBudget({
        id: this.budgetId,
        // name: this.formquery.name,
        dspIdList: this.editList.filter((item) => item.dspId !== row.dspId).map((item) => item.dspId)
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success('删除成功')
          this.getDspListByBudget()
        }
      })
    },
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    async getDspListByBudget () {
      const { data = [] } = await getDspListByBudget({
        id: this.budgetId
      })
      this.renderList = data
      this.editList = data
    },
    handleSearchDsp () {
      let renderList = [...this.editList]
      if (this.query.platIdList.length) {
        renderList = this.editList.filter((item) => this.query.platIdList.includes('' + item.platId))
      }
      if (this.query.key) {
        renderList = renderList.filter(
          (item) =>
            ('' + item.platAccountId).includes(this.query.key) ||
            ('' + item.dspId).includes(this.query.key) ||
            item.dspName.includes(this.query.key) ||
            item.platAccountName.includes(this.query.key)
        )
      }
      this.renderList = renderList
    },
    handleAddPlace () {
      this.addSourceVisible = true
    },
    configSourceSuccess (e) {
      this.getDspListByBudget()
    },
    handleCancel () {
      this.addBudgetVisible = false
    },
    // 提交
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          updateBudget({
            name: this.formquery.name,
            id: this.budgetId,
            dspIdList: this.editList.map((item) => item.dspId),
            isDefault: this.formquery.isDefault,
            positionList: this.formquery.positionList,
            osList: this.formquery.osList
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success('修改成功')
              this.addBudgetVisible = false
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.traffic-modal-container {
  .contral-box {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    .contral-left {
      display: flex;
      justify-content: flex-start;
    }
    .contral-right {
      display: flex;
      align-items: flex-start;
    }
  }
  .search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-top: 10px;
  }
  .source-list-wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    border: 1px solid #eee;
    height: 400px;
    overflow-y: auto;
    .source-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: #fafafa;
      margin-bottom: 3px;
      min-height: 50px;
      .item-box {
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.head {
        font-weight: 500;
        min-height: 45px;
        background-color: #eee;
      }
    }
  }
}
.info-box {
  display: flex;
  justify-content: space-between;
  .copy {
    color: @primary-color;
    cursor: pointer;
    padding: 0 5px;
  }
}
</style>
